import React, {useMemo} from 'react'
import noop from 'lodash/noop'

import {BestSellersContainer} from './BestSellers.styles'
import {Section, SectionWithOffset} from '../../../ComponentsV2/common'
import {ProductListing} from '../ProductListing'
import Responsive from '../../../ComponentsV2/HOC/Responsive'
import {ViewAllButton} from '../ViewAllButton'
import {withCustomErrorBoundary} from '../../../utils/withCustomErrorBoundary'
import isEmpty from 'lodash/isEmpty'

function BestSellers(props) {
  const {state = {}, actions = {}, children, isMobile = false} = props || {}
  const {onViewAllClick = noop} = actions

  const {
    backgroundImageDesktop = '',
    backgroundImageMobile = '',
    title = '',
    subtitle = '',
    products = [],
    sectionName = 'bestSellers',
    viewAllLink = '',
    productListingConfig = {},
  } = state || {}

  const productListingState = useMemo(() => {
    return {products, productListingConfig, sectionName}
  }, [products, productListingConfig, sectionName])

  if (isEmpty(products)) {
    return null
  }

  return (
    <BestSellersContainer data-section={sectionName}>
      <SectionWithOffset
        state={{backgroundImageDesktop, backgroundImageMobile}}
      >
        <Section
          state={{title, subtitle, viewAllLink}}
          actions={{onViewAllClick}}
        />
        <ProductListing state={productListingState} actions={actions} />
        {isMobile && (
          <ViewAllButton state={{viewAllLink}} actions={{onViewAllClick}} />
        )}
      </SectionWithOffset>
      {children}
    </BestSellersContainer>
  )
}

export default Responsive(withCustomErrorBoundary(BestSellers, 'BestSellers'))
