import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import noop from 'lodash/noop'
import {useCallback} from 'react'

import {useLocalisationContext} from '../../../ComponentsV2/Context/Localisation/Localisation'
import {ViewAllButtonContainer} from './ViewAllButton.styles'

function ViewAllButton(props) {
  const {VIEW_ALL_TEXT = 'View All'} = useLocalisationContext()
  const {state = {}, actions = {}} = props
  const {label = VIEW_ALL_TEXT, viewAllLink = ''} = state
  const {onViewAllClick = noop} = actions

  const handleViewAllClicked = useCallback(
    () => onViewAllClick(viewAllLink),
    [onViewAllClick, viewAllLink]
  )

  return (
    <ViewAllButtonContainer onClick={handleViewAllClicked}>
      {label} <FontAwesomeIcon icon={faChevronRight} className="arrow-right" />
    </ViewAllButtonContainer>
  )
}

export default ViewAllButton
