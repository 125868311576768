import styled from 'styled-components'

export const ViewAllButtonContainer = styled.button`
  outline: 0;
  border: 0;
  background-color: var(--brand-primary-white-color);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  color: var(--category-primary-color);
  margin-top: 20px;
  font-family: var(--brand-font-family-text);
  font-size: 14px;
  line-height: 1;
  font-weight: var(--brand-font-weight-black);
  padding: 14px;

  .arrow-right {
    margin-left: 8px;
  }
`
