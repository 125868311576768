import styled from 'styled-components'

export const BestSellersContainer = styled.div`
  width: 100%;
  margin: auto auto 60px auto;

  @media screen and (min-width: 768px) {
    margin: auto auto 120px auto;
  }
`
